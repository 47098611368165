import { useDetectAdBlock } from 'adblock-detect-react'
import { useState } from 'react'
import { AdBlockAlertContext } from 'context/adBlockAlert'

function InnerComponent({ children }: { children: JSX.Element }) {
  const adBlockDetected = useDetectAdBlock()
  const [bannerHeight, setBannerHeight] = useState(0)

  const value: AdBlockAlertContext = {
    adBlockDetected,
    bannerHeight,
    setBannerHeight,
  }

  return <AdBlockAlertContext.Provider value={value}>{children}</AdBlockAlertContext.Provider>
}

export default function AdBlockAlertProvider({ children }: { children: JSX.Element }) {
  return <InnerComponent>{children}</InnerComponent>
}
