import { useState } from 'react'
import { useAuthContext } from 'context'
import { SystemAlertContext } from 'context/systemAlert'
import { useActiveSystemAlert } from 'hooks/api/systemAlert'

function InnerComponent({ children }: { children: JSX.Element }) {
  const { systemAlert } = useActiveSystemAlert()
  const [bannerHeight, setBannerHeight] = useState(0)

  const value: SystemAlertContext = {
    systemAlert,
    bannerHeight,
    setBannerHeight,
  }

  return <SystemAlertContext.Provider value={value}>{children}</SystemAlertContext.Provider>
}

export default function SystemAlertProvider({ children }: { children: JSX.Element }) {
  const { authed } = useAuthContext()

  if (!authed) return children

  return <InnerComponent>{children}</InnerComponent>
}
