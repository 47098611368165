import {
  INSTACART_SANS_THEME_TYPOGRAPHY,
  IdsProvider,
  LEGACY_CORE_COLORS,
} from '@instacart/ids-core'
import Radium from '@instacart/radium'
import { TdsProvider } from '@instacart/tds'
import { IntlProvider } from 'react-intl'
import AdBlockAlertProvider from 'components/AdBlockAlert/AdBlockAlertProvider'
import { AuthProvider, CapabilitiesProvider } from 'components/Auth'
import DebuggingProvider from 'components/Header/DebuggingProvider'
import NotificationsProvider from 'components/molecules/Notifications/NotificationsProvider'
import SystemAlertProvider from 'components/SystemAlert/SystemAlertProvider'
import { LayoutProvider } from 'context'
import messages from 'locales/en-US.json'
import { GlobalTdsStyles } from './GlobalStyles'
import Routes from './Routes'

const App = () => {
  return (
    <IntlProvider locale="en-US" messages={messages}>
      <IdsProvider
        themeOverrides={{
          colors: LEGACY_CORE_COLORS,
          typography: INSTACART_SANS_THEME_TYPOGRAPHY,
        }}
      >
        <TdsProvider>
          <Radium.StyleRoot>
            <NotificationsProvider>
              <AuthProvider>
                <SystemAlertProvider>
                  <AdBlockAlertProvider>
                    <CapabilitiesProvider>
                      <DebuggingProvider>
                        <LayoutProvider>
                          <GlobalTdsStyles />
                          <Routes />
                        </LayoutProvider>
                      </DebuggingProvider>
                    </CapabilitiesProvider>
                  </AdBlockAlertProvider>
                </SystemAlertProvider>
              </AuthProvider>
            </NotificationsProvider>
          </Radium.StyleRoot>
        </TdsProvider>
      </IdsProvider>
    </IntlProvider>
  )
}

export default App
