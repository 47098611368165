import { useCallback } from 'react'
import { SystemAlertsApi } from 'service/apis/SystemAlertsApi'
import { SystemAlertsActiveResponse } from 'service/openapi/__codegen__/models/SystemAlertsActiveResponse'
import { useApiFn, UseApiFnOptions } from './base'

type UseActiveSystemAlertOptions = UseApiFnOptions<SystemAlertsActiveResponse>

export function useActiveSystemAlert({ throwError = false }: UseActiveSystemAlertOptions = {}) {
  const fn = useCallback(() => SystemAlertsApi.getSystemAlertsActive({}), [])

  const { loading, error, response } = useApiFn(fn, { throwError })

  return { loadingSystemAlert: loading, systemAlert: response?.data?.attributes || null, error }
}
