import { useEffect } from 'react'
import * as ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'
import initAnalyticsProvider from 'common/analytics/AnalyticsProvider'
import { initDataDogRUM } from 'common/dataDog'
import { isWebpackDevServer } from 'common/utils'
import App from 'components/App'
import { initAhoy } from './common/ahoyInit'
import { BaseStyles } from './components/BaseStyles'

const BrowserApp = () => {
  useEffect(initAnalyticsProvider, [])
  useEffect(initDataDogRUM, [])

  return (
    <BrowserRouter>
      <CompatRouter>
        {isWebpackDevServer() && <BaseStyles />}
        <App />
      </CompatRouter>
    </BrowserRouter>
  )
}

ReactDOM.hydrate(<BrowserApp />, window.document.getElementById('__ads_root'))

initAhoy()

export default BrowserApp
